import { IImageDto, IProduct, IVariantDto } from "../api/models/product";

import { removeRepeatedObjects } from "./array";

export function getAllUniqueImagesFromProduct(product: IProduct) {
  const generalImages = product.images ?? [];
  const imagesPerKey: Record<string, (IImageDto & { related?: string[] })[]> = {};

  product.variants?.forEach((variant) => {
    // Previne adição de imagens repetidas (e.g. 3 imagens iguais porque existem 3 tamanhos da mesma cor)
    const key = variant.metadata.COLOR ?? variant.id;
    if (!imagesPerKey[key]) {
      imagesPerKey[key] = variant.images;
    } else {
      imagesPerKey[key].forEach((image) => {
        image.related ??= [];
        variant.images.forEach((relatedImage) => {
          image.related!.push(relatedImage.url);
        });
      });
    }
  });

  const variantImages = Object.values(imagesPerKey).flat(1);

  const uniqueImages = <(IImageDto & { related?: string[] })[]>(
    removeRepeatedObjects([...generalImages, ...variantImages], ({ url }) => url).filtered
  );

  return uniqueImages;
}

export function getProductVariantById(
  product: Partial<IProduct>,
  variantId: string
): IVariantDto | undefined {
  return product.variants?.find(({ id }) => variantId === id);
}
