export function transformStringArrayToStringWithSeparation(array: string[] | null): string | null {
  if (!array) {
    return null;
  }

  const { length } = array;

  switch (length) {
    case 0:
      return "";

    case 1:
      return array[0];

    case 2:
      return `${array[0]} e ${array[1]}`;

    default:
      // retira o último elemento com o slice, une todos com ", " e adiciona o "e" no último elemento
      return `${array.slice(0, length - 1).join(", ")} e ${array[length - 1]}`;
  }
}

export function sortArrayOfStrings(array: string[], direction?: "ASC" | "DESC") {
  return array.sort((a, b) => (direction === "DESC" ? b.localeCompare(a) : a.localeCompare(b)));
}

export function sortArrayOfObjects({
  array,
  key,
  direction,
}: {
  array: Record<string, any>[];
  key: string;
  direction: "ASC" | "DESC";
}) {
  return array.sort((a: Record<string, any>, b: Record<string, any>) => {
    const aValue = a[key];
    const bValue = b[key];

    const isASC = direction === "ASC";

    if ((!aValue || typeof aValue === "string") && (!bValue || typeof bValue === "string")) {
      switch (direction) {
        case "ASC":
          return (aValue ?? "").localeCompare(bValue ?? "");
        case "DESC":
          return (bValue ?? "").localeCompare(aValue ?? "");
        default:
          return 0;
      }
    }

    if ((a[key] === null || a[key] === undefined) && b[key]) return isASC ? 1 : -1;
    if (a[key] && (b[key] === null || b[key] === undefined)) return isASC ? -1 : 1;

    if (a[key] < b[key]) return isASC ? -1 : 1;
    if (a[key] > b[key]) return isASC ? 1 : -1;

    return 0;
  });
}

/**
 * Retorna todas as combinações entre os itens do array, sempre com 1 item a menos
 *
 * @example
 * combinationsOfNMinus1([1,2,3])
 * //[[1,2], [2,3], [1,3]]
 */
export function combinationsOfNMinus1<T>(array: T[]): T[][] {
  const result: T[][] = [];

  array.forEach((_, index) => {
    // Pega todos os itens, exceto o item no índice index
    const combination = [...array.slice(0, index), ...array.slice(index + 1)];

    if (combination.length) {
      result.push(combination);
    }
  });

  return result;
}

export function removeRepeatedObjects<T>(array: T[], callback: (item: T) => any = (item) => item) {
  const repeated: T[] = [];

  const filtered = array.filter((upperItem, index) => {
    const firstIndexOfFirstOccurrence = array.findIndex(
      (item) => callback(upperItem) === callback(item)
    );
    const isUnique = firstIndexOfFirstOccurrence === index;

    if (!isUnique) {
      repeated.push(upperItem);
    }

    return isUnique;
  });

  return { repeated, filtered };
}

export function orderAlphabetically<T = any>(
  array: T[],
  valueCallback: (item: T) => string = (item) => String(item)
) {
  return array.sort((prev, curr) => (valueCallback(prev) < valueCallback(curr) ? -1 : 1));
}
